import React, { useRef, useState, useEffect } from 'react';
import "./Video.css"
import VideoFooter from './VideoFooter';
import VideoSideBar from './VideoSideBar';

function Video({ url, channel, description, song, likes, shares, messages }) {
    
    const [playing, setPlaying] = useState(false);
    const videoRef = useRef(null);
    
    const onVideoPress = () => {
        if(playing) {
            videoRef.current.pause();
            setPlaying(false);
        } else {
            videoRef.current.play();
            setPlaying(true);
        }
    }
    
    return (
        <div className="video">
            <video className="video__player" 
            loop
            onClick={onVideoPress}
            ref={videoRef}
            // controls TO SHOW PLAY PAUSE TIME CONTROLS
            src={url}/>
            <VideoFooter
            channel={channel}
            description={description}
            song={song}
            />
            <VideoSideBar
            likes={likes}
            messages={messages}
            shares={shares}
            />
        </div>
    )
}

/*
https://v16m.tiktokcdn.com/1375312ae89d372e952f5ae5b129da83/5f25cff8/video/tos/useast2a/tos-useast2a-ve-0068c001/a4c4b91688194befa271fe2b72aba70a/?a=1233&br=2054&bt=1027&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=M2p0aXd2cXY8czMzODczM0ApODY7Z2U7NWU4Nzw7PGdmN2czMDBwajNfcXJfLS00MTZzczI2NGAyX2A2MjAzNV5eXzE6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/bb86053b0005a051952cc07c7c8fa826/5f25d00e/video/tos/useast2a/tos-useast2a-ve-0068c002/f40253be07f94123ad5a4a55bd1edaa8/?a=1233&br=2880&bt=1440&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=aml2M2htZzc1czMzNTczM0ApOztoNDtlM2Q7NzczZzM4ZGdtLzYucWpkLV5fLS0tMTZzc2BiYTE0YTAuLmI0NGA0MjI6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/0c2d30e4e9a15058fc70f5a0b61e12af/5f25d00a/video/tos/useast2a/tos-useast2a-ve-0068c002/6d11419b362b40a6b1389548b275353b/?a=1233&br=4546&bt=2273&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=ajtycGs7dTtncjMzNTczM0ApMzloNjQ8OmUzN2VlZTtnNmdgZHJlNDJfL29fLS01MTZzc2NgYl40YzFhX2A1MGFjNGA6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/fece2df5deac45c8784c49b946c03ad6/5f25cffd/video/tos/useast2a/tos-useast2a-ve-0068/e31fc65bd42c4c8d806bf1df8d495995/?a=1233&br=2274&bt=1137&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=M244aDNkbnMzcjMzNTczM0ApNmc6Mzk8OWU8NzRmZzw7NWdsYWhyYWgyMGFfLS0wMTZzcy0zMl82MS4uLjReNF81Li46Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/0b8647d36450fce6972180ddc048d941/5f25d02c/video/tos/useast2a/tos-useast2a-ve-0068/e53e3c92908f4cc39ca5f67db4a94ef2/?a=1233&br=1122&bt=561&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=M2R4OzVuZ3M4cDMzZzczM0ApM2k2NTc2OWRoNzQ3ZWU7OWduLi80czYzZTNfLS01MTZzczEyYGEzNTY1YzI1YzBhYjQ6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/751047f28d37061c573c4a53a88bc328/5f25d011/video/tos/useast2a/tos-useast2a-ve-0068/d1d6f67bb7e046d4a094a36e3cd9fa6f/?a=1233&br=7276&bt=3638&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=MzZ4ZHh2eTlybDMzOzczM0ApZzg6Ozk6OzxmNzhkOTtoM2dvX2FkaGMvYG5fLS00MTZzc2FhYDJeMS0uNDM0YS9iNF46Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/71b00e2acfbc156111ec9c49474707a0/5f25d01b/video/tos/useast2a/tos-useast2a-ve-0068/b47466412e19485086f5bcf3f38bca70/?a=1233&br=6724&bt=3362&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=M2t3ZXc7NHc1bDMzODczM0ApOzs6O2hoaWVpNzc8ZGU3Z2czYzNzZC1oL19fLS00MTZzczItYzAvLjRjNWEyMF8wYmA6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/71b00e2acfbc156111ec9c49474707a0/5f25d01b/video/tos/useast2a/tos-useast2a-ve-0068/b47466412e19485086f5bcf3f38bca70/?a=1233&br=6724&bt=3362&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=M2t3ZXc7NHc1bDMzODczM0ApOzs6O2hoaWVpNzc8ZGU3Z2czYzNzZC1oL19fLS00MTZzczItYzAvLjRjNWEyMF8wYmA6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/71b00e2acfbc156111ec9c49474707a0/5f25d01b/video/tos/useast2a/tos-useast2a-ve-0068/b47466412e19485086f5bcf3f38bca70/?a=1233&br=6724&bt=3362&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=M2t3ZXc7NHc1bDMzODczM0ApOzs6O2hoaWVpNzc8ZGU3Z2czYzNzZC1oL19fLS00MTZzczItYzAvLjRjNWEyMF8wYmA6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/7dba207744a2db091abad4f502554fac/5f25cffa/video/tos/useast2a/tos-useast2a-ve-0068/2cbd9d510da340e19157033b6773ad4a/?a=1233&br=2622&bt=1311&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=ank2cjY0eWx4azMzaTczM0ApOjpkaGRlMzxkNzZnaTw7OWdnYTRuNnIvZS9fLS0uMTZzczIwYl80MS4wM141NGBeLjE6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/a71b90732ae2680477235dacbd66a1d0/5f25cfff/video/tos/useast2a/tos-useast2a-ve-0068/28e7f62be68c4d93b19984f2cf2e41de/?a=1233&br=1896&bt=948&cr=0&cs=0&dr=0&ds=1&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=amVscXlwdjU2ajMzOTczM0ApMzxpNDc1NTxoNzc8ZDw2PGdsbGRsbmpjMW1fLS0vMTZzczMxXy1hNTZiNWJeMTVeYS46Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/87ed85d1b4c203f15afac6564e43bebd/5f25d001/video/tos/useast2a/tos-useast2a-ve-0068/2e63cec36ecb456cb7b14aaed3809190/?a=1233&br=3576&bt=1788&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=ajVtOHE0aWc0ajMzMzczM0ApOTxmZWhkOjs3NzdlOzRnNGdsL3NlXi9iNWNfLS0yMTZzczJeNGAyNi0uYWFfLzBgM186Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/9c17a3aa3a5afbdc8f2320226f9e074c/5f25cffa/video/tos/useast2a/tos-useast2a-ve-0068/05150b3f54344e8186fe70933d9f246c/?a=1233&br=2376&bt=1188&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=M21pd3F2eXY8aTMzNjczM0ApOzZoZmk7aDxkN2dpaWVpOWcuM2FkaGMvM3NfLS0xMTZzc182YzVhNjFfNi5hLi5eLjI6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/478d9232732c014df2360eb4d7561184/5f25d000/video/tos/useast2a/tos-useast2a-ve-0068/5388d20436db4926a594198237debfc7/?a=1233&br=3368&bt=1684&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=ajlwaHV2eW1qaTMzNTczM0ApODg2NTM2OTxnN2VmZTU5M2dqZW9jaGNeaWlfLS0xMTZzc14zYi8tXmBgYy8zNDMwXmA6Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/50c6fd7a05acf1128a7a7d751a4b4ba5/5f25cfff/video/tos/useast2a/tos-useast2a-ve-0068/26b042ceddb54aa3bb1f5e40ec6eeb7c/?a=1233&br=1352&bt=676&cr=0&cs=0&dr=0&ds=1&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=M3Q5czQ5b3JpaTMzOTczM0ApPDVpNjc5aDxkNzM4NWU2OGdfbGoxXzZvamlfLS1iMTZzc15eNmFjYzRiLS9fXzFiMi86Yw%3D%3D&vl=&vr=
https://v16m.tiktokcdn.com/d63798264c213ed8c1de20dccdc07eeb/5f25cfff/video/tos/useast2a/tos-useast2a-ve-0068/0ccec1b06d7446ddb54b643a249c8ced/?a=1233&br=4412&bt=2206&cr=0&cs=0&dr=0&ds=3&er=&l=202008011426250101891950151D2BCCAA&lr=tiktok_m&mime_type=video_mp4&qs=0&rc=am0zPGp1bTp2aTMzNzczM0ApODNnN2g5OmRlNzU3ZzU5OGdrY2RebG4vMTFfLS01MTZzczU1L140YmJeNTAwX140Y2M6Yw%3D%3D&vl=&vr=
*/

export default Video
