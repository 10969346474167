import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCEV6KgCgJqdoB39gY4yqMU2c1SYjZog-o",
    authDomain: "tik-tok-clone-671a4.firebaseapp.com",
    databaseURL: "https://tik-tok-clone-671a4.firebaseio.com",
    projectId: "tik-tok-clone-671a4",
    storageBucket: "tik-tok-clone-671a4.appspot.com",
    messagingSenderId: "1014655873619",
    appId: "1:1014655873619:web:842c57bd92b3805a060ca6",
    measurementId: "G-9ER5N0EX01"
  };
  
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  
  const db = firebaseApp.firestore();
  
  export default db;