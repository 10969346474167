import React, { useState, useEffect } from 'react';
import './App.css';
import Video from './Video';
import db from './firebase';

function App() {

  const [videos, setVideos] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);
  
  useEffect(() => {
    db.collection('videos').onSnapshot(snapShot => 
      (setVideos(snapShot.docs.map(doc => doc.data()))))
  }, [videos]);
  return (
    <div className="app">
      <div className="app__videos">
        {videos.map(
          ({ url, channel, description, song, likes, shares, messages }, index) =>
            (<Video
            key={index}
              url={url}
              channel={channel}
              description={description}
              song={song}
              likes={likes}
              shares={shares}
              messages={messages}
            />)
        )}
      </div>
    </div>
  );
}
// <video sequence="0" playsinline="" loop="" src="https://v16m.tiktokcdn.com/ec7e3df329e4f1179306c601c4b8196a/5f25ddb3/video/tos/useast2a/tos-useast2a-ve-0068c001/a4c4b91688194befa271fe2b72aba70a/?a=1233&amp;br=2054&amp;bt=1027&amp;cr=0&amp;cs=0&amp;dr=0&amp;ds=3&amp;er=&amp;l=202008011525000101901871633533C356&amp;lr=tiktok_m&amp;mime_type=video_mp4&amp;qs=0&amp;rc=M2p0aXd2cXY8czMzODczM0ApODY7Z2U7NWU4Nzw7PGdmN2czMDBwajNfcXJfLS00MTZzczI2NGAyX2A2MjAzNV5eXzE6Yw%3D%3D&amp;vl=&amp;vr=" preload="metadata" class="jsx-3900254205 horizontal video-player"></video>

export default App;
